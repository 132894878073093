import React from 'react';
import { navigate } from 'gatsby-link';
import { StaticImage } from 'gatsby-plugin-image';

import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';

import WorksMessage from '@/components/molecules/index/WorksMessage';
import LinkBox from '@/components/molecules/index/LinkBox';
import ImageLinkBox from '@/components/molecules/index/ImageLinkBox';

const linkImage = css({
  height: '100%',
});

const WorksBlock: React.VFC = () => {
  return(
    <section>
      <Grid container>
        <Grid item xs={12} md={8}>
          <WorksMessage />
        </Grid>
        <Grid item xs={12} md={4}>
          <LinkBox
            backgroundColor="secondary"
            linkTitleText={['Our Works']}
            linkButtonText="施工実績を見る"
            handleClick={() => navigate('/works')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageLinkBox
            labelName="建築施工例"
            labelNameEn="Architecture"
            handleClick={() => navigate('/works#architecture')}
          >
            <StaticImage
              alt="architecture image"
              src="../../../images/architecture/image10.jpg"
              css={linkImage}
              layout="fullWidth"
            />
          </ImageLinkBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageLinkBox
            labelName="リフォーム施工例"
            labelNameEn="Renovation"
            handleClick={() => navigate('/works#renovation')}
          >
            <StaticImage
              alt="renovation image"
              src="../../../images/renovation/image07.jpg"
              css={linkImage}
              layout="fullWidth"
            />
          </ImageLinkBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageLinkBox
            labelName="土木施工例"
            labelNameEn="Civil Engineering"
            handleClick={() => navigate('/works#civilEngineering')}
          >
            <StaticImage
              alt="civil engineering image"
              src="../../../images/civilEngineering/image01.jpg"
              css={linkImage}
              layout="fullWidth"
            />
          </ImageLinkBox>
        </Grid>
      </Grid>
    </section>
  );
};

export default WorksBlock;
