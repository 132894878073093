import React from 'react';

import { css } from '@emotion/react';
import Box from '@mui/material/Box';

import ImageLinkLabel from '@/components/atoms/common/ImageLinkLabel';

type imageLinkProps = {
  children: React.ReactNode;
  labelName: string;
  labelNameEn: string;
  handleClick?: () => void;
};

const imageLinkBox = css({
  position: 'relative',
  height: '30vh',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
});

const ImageLinkBox: React.VFC<imageLinkProps> = (props) => {
  const {children, labelName, labelNameEn, handleClick} = props;

  return(
    <Box css={imageLinkBox} onClick={handleClick}>
      {children}
      <ImageLinkLabel
        labelName={labelName}
        labelNameEn={labelNameEn}
      />
    </Box>
  );
};

export default ImageLinkBox;
